<script setup lang="ts">
import {useMyRoutePaths} from "~/composables/route/useMyRoutePaths";
import {useMyAdminSession} from "~/stNuxtCommon/composables/session/useMyAdminSession";

const routePaths = useMyRoutePaths()
const session = useMyAdminSession()
const router = useRouter()

const logout = async (e: Event) => {
  e.preventDefault()
  session.clear()
  await router.replace(routePaths.login())
}
</script>

<template>
  <div class="sidebar-wrap">
    <div class="sidebar min-vh-100">
      <ul class="sidebar-menu-list">
        <li>
          <NuxtLink :to="routePaths.admin.home()">ダッシュボード</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.bucketImages()">Bucket画像</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.posts()">記事</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.postSeriesList()">記事シリーズ</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.areaMaps()">エリアマップ</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.areaGuides()">エリアガイド</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.selectableTags()">選択可能タグ</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.journals()">旅日記</NuxtLink>
        </li>
        <li>
          <NuxtLink href="/admin/youTube">YouTube</NuxtLink>
        </li>
        <li>
          <NuxtLink href="/admin/facebook/auth">Facebook認証</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.accessLogs()">アクセスログ</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.admin.twoFactor()">二要素認証</NuxtLink>
        </li>
        <li>
          <NuxtLink :to="routePaths.home()" target="_blank">サイトを見る</NuxtLink>
        </li>
        <li>
          <a href="#" @click="logout">ログアウト</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.sidebar-wrap {
  display: block;
  width: 200px;

  .sidebar {
    padding: 30px;
  }
  .sidebar-menu-list {
    display: block;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    li {
      margin: 8px 0;
      a {
        text-decoration: none;
      }
    }
  }
}
</style>
